import React, { useState, useEffect } from "react";
import "./LandingPage.css";

const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [industry, setIndustry] = useState("");
  const [error, setError] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [honeypot, setHoneypot] = useState("");
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [timestamp, setTimestamp] = useState(null);

  useEffect(() => {
    setTimestamp(Date.now());
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailFocus = () => {
    if (email === "@") {
      setEmail("");
    }
  };

  const handleEmailBlur = () => {
    if (email === "@") {
      setEmail("");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    const isValid = validateEmail(e.target.value) || e.target.value === "";
    setEmailValid(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(false);

    // Check if honeypot has any value
    if (honeypot) {
      // It's likely a bot submission
      return;
    }

    // Check if form was submitted too quickly
    if (timestamp) {
      const currentTime = Date.now();
      const timeDifference = currentTime - timestamp;
      if (timeDifference < 1000) {
        // The form was submitted too quickly (less than 1 second), likely a bot
        return;
      }
    }

    const isEmpty = email === "";
    setEmailEmpty(isEmpty);

    const isValid = validateEmail(email) || email === "";

    if (!isValid || isEmpty || industry === "") {
      setError("Please enter email, choose industry. Thanks!");
      setEmailValid(isValid);
      // Set formSubmitted to true if there's an error
      setFormSubmitted(true);
      return;
    }

    setError("");
    setSubmittedSuccessfully(true);

    // Submit the data to the Netlify function
    const response = await fetch("/.netlify/functions/submit-data", {
      method: "POST",
      body: JSON.stringify({ email, industry }),
    });

    if (response.ok) {
      setSubmittedSuccessfully(true);
      setEmail("");
      setIndustry("");
    } else {
      alert("Error submitting data. Please, try again.");
    }
  };

  return (
    <div className="pageContainer">
      <div className="mainframe">
        <div className="hero">
          <div className="logo" />
          <p className="arriving">Arriving 2023</p>
          <h1 className="landingpage-2023">
            What truly matters to your customers?
          </h1>
          <p className="subtitle-example-text">
            Learn at scale with AI—powered research instruments.
          </p>
        </div>
        {!submittedSuccessfully ? (
          <form className="form" onSubmit={handleSubmit} noValidate>
            <input
              className={`input_email${
                (!emailValid || emailEmpty) && formSubmitted
                  ? " invalid-email"
                  : ""
              }`}
              type="email"
              placeholder={email === "" ? "@" : undefined}
              value={email}
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
              onChange={handleEmailChange}
            />

            <select
              className={`input_select${
                formSubmitted && industry === "" ? " invalid-select" : ""
              }`}
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            >
              <option value="">Industry</option>
              <option value="automotive">Automotive</option>
              <option value="entertainment">Entertainment</option>
              <option value="fintech">Fintech</option>
              <option value="healthcare-pharma">Healthcare & Pharma</option>
              <option value="non-profit-education">
                Non-profit & Education
              </option>
              <option value="sport-lifestyle">Sport & Lifestyle</option>
              <option value="telecommunications">Telecommunications</option>
              <option value="travel-hospitality">Travel & Hospitality</option>
              <option value="transportation">Transportation</option>
              <option value="other">Other</option>
            </select>
            <div className="submit-container">
              <button className="submit-button" type="submit">
                Join the waitlist
              </button>
            </div>
            <input
              type="text"
              name="honeypot"
              id="honeypot"
              style={{ display: "none" }}
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
            />
            <input type="hidden" name="timestamp" value={timestamp} />
          </form>
        ) : (
          <div className="form success-message">
            <div className="checkmark-icon" />
            Thanks for joining. You are waitlisted.
          </div>
        )}
        {error && <p className="error-message">{error}</p>}
      </div>
      <div className="footer">
        <div className="footer-logo" />
        <p className="footer-we-are">「 research instruments 」</p>
        <div className="footer-links">
        <a
            href="https://read.cv/teams/rins"
            className="footer-info-link privacy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            about
          </a> 
          <a
            href="/PrivacyPolicy.pdf"
            download="PrivacyPolicy.pdf"
            className="footer-info-link privacy-link"
          >
            privacy
          </a> 
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
